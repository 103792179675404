import rehypeRaw from 'rehype-raw';
import rehypeRewrite from 'rehype-rewrite';

import ReactMarkdown from '@starcar/react-markdown';

import {hasDeepStrongElement} from './MarkdownContent';

const ReactMarkdownFallback = props => {
  return (
    <ReactMarkdown
      {...props}
      rehypePlugins={[
        rehypeRaw as any,
        [
          rehypeRewrite as any,
          {
            rewrite: (node, index, parent) => {
              if (node.tagName === 'li' && hasDeepStrongElement(node)) {
                node.isBold = true;
              }

              if (
                ['h1', 'h2', 'h3', 'h4', 'h5'].includes(node.tagName) &&
                !!node?.children[0]?.properties?.className?.filter(className =>
                  ['h1', 'h2', 'h3', 'h4', 'h5'].includes(className),
                )
              ) {
                node.properties.className = node?.children[0]?.properties?.className;
                node.children = node?.children[0]?.children;
                // node.properties = {}
              }
            },
          },
        ],
      ]}
    />
  );
};
export default ReactMarkdownFallback;
